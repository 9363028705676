
.savKubernetesTable {
 
  color: var(--Dark, hsl(220, 4%, 14%));
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
}
.naText{
  color: #000;
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}
.hovered {
  background-color: #F0F6FF;
}
.HeaderMainDiv {
  display: flex;
  padding: 16px 40px 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  background: #0f2573;
  margin-left: 0px;
}
.CEDIV {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.5;
}
.headingh4 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.upto_percentage {
  border-radius: 2px;
  background: rgba(20, 197, 65, 0.12);
  display: flex;
  padding: 2px 6px 4px 6px;
  align-items: flex-start;
  gap: 10px;
  color: var(--Success, #3da64f);
  font-family: Inter;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* word-wrap: break-word; */
}
.calmaindiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}
.calheading {
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.second_part {
  display: flex;
  padding: 16px 24px 16px 16px;
  padding: 16px 24px 16px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: #f8f9fa;
  align-self: stretch;
}
.IdleResource_div {
  position: relative;
  display: flex;
  min-width: 150px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 0 20px;
  flex: 1;
}
.IdleResource_div:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #e9ecef;
}
.IdleResource_div:last-child:after {
  display: none;
}
.id_first_div {
  display: flex;
  /* height: 66px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}
.ComboSECONDInnerDIv1 {
  display: flex;
  align-items: flex-start;
  gap: 21px;
}
.period_cost1:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #e9ecef;
}
.period_cost1:last-child:after {
  display: none;
}
.ComboSECONDDiv1 {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.period_cost1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  padding-right: 24px;
  flex: auto;
}
.preceding_header {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.preceding_text {
  color: #495057;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.preceding_cost {
  color: var(--neutral-d-2, #343a40);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.name_part_css {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  line-height: 18.15px;
}
.value_with_inc {
  display: flex;
  /* align-items: flex-end; */
  gap: 8px;
  align-items: center;
}
.id_span_value {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.acted_upon_div{
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
}
.id_second_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  padding-bottom: 20px;
}

.acted_upon {
  color: var(--Neutral-D4, #797b80);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
}
.id_value_css {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.idle_res_name {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.transparent-datepicker {
  background: transparent !important;
  border: none !important;
  /* width: auto; */
  /* width: 85px; */
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.calsecheading1 {
  /* color: #fff; */
  background: #101d4f;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border: none;
  border-radius: 4px;
  padding: 6px 10px 7px 11px;
}
.tab_css {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: rgba(255, 255, 255, 0.18);
  color: #fff;
}
.TPComboDiv {
  color: var(--Neutral-D4, #797B80);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  line-height: 18.15px;
}
.LDaysDiv {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.totalSavingsBox {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 2px;
}
.totalSaving {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 16px 16px 24px;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px 0px 0px 8px;
  background: var(--White, #fff);
  flex-shrink: 1;
}
.ComboMainDIv1 {
  /* display: flex;
  align-items: flex-start;
  margin-right: 0px;
  margin-left: 0px; */
  margin: 0px;
  background-color: #fff;
  border-radius: 12px;
}
/* ------------------------------------------------------------- */
.all_cloud_text {
  color: #212224;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 19.36px;
}
.menu_icon_css1 {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.last_days_name {
  /* margin-left: 4px; */
  padding:3px;
  color: var(--neutral-d-5, #adb5bd);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}
 

.icc_main_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* margin-left: 40px; */
}

.graph_main_div {
  display: flex;
  justify-content: flex-start;
  gap: 16px !important;
}

.cost_graph {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
}

.aws_costs_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 5px;
}

.aws_cost_name {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aws_cost_value {
  color: #495057;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

.buttons_css {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.aws_cost_text_div {
  padding: 0px;
  display: flex;
  gap: 12px;
}

.aws_cost_text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
/* -----------------------------bargraph tooltip-------------------------------------- */
.custom_tooltip {
  background-color: black;
  border: 1px solid #000;

  border-radius: 8px;
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.custom_tooltip table {
  width: 100%;
}

.custom_tooltip th {
  color: white;
  padding: 8px;
  align-items: flex-start;
  color: var(--nn-4, #a09fa5);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cloud_cell {
  /* width: calc(50% - 20px); */
  width: 100%;
  color: var(--Background-module, #f0f0f0);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
tr.horizontalline_tool {
  border-bottom: 1px solid #373737;
  width: 100%;
  padding-left: 24px;
}

.spacer_cell {
  width: 55px;
}

.cost_cell {
  /* width: calc(50% - 20px); */
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transform: translate(30%, 0%);
}

.custom_tooltip td {
  padding: 8px;
  color: white;
  align-items: flex-start;
}
.cloud_logo {
  width: 16px;
  height: 16.5px;
  flex-shrink: 0;
  margin-right: 8px;
  align-items: flex-end;
}
.tooltip_date_css {
  color: var(--neutral-d-7, #dee2e6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
}
.tooltip_date {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: #2c2c2c;
  padding-bottom: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  border-bottom: 1px solid #373737;
}
/* --------------------------------dropdown css ------------------------------------ */
#dropdown_item_button {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  border: none;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#dropdown_menu_item {
  padding: 0px;
  margin: 0px;
  border: none;
  background: transparent;
}

#dropdown_header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
  border: none;
}
#dropdown_basic_check {
  font-size: 13px;
  background: transparent;
  color: black;
}

.dropdown_item:hover {
  border-radius: 4px !important;

  background: var(--Controls-BG, #f0f6ff) !important;
}
.dropdown_menu {
  padding: 8px 8px !important;
}
.dropdown_item {
  color: #000 !important;
  padding: 4px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
.custom_dropdown_toggle::after {
  content: none;
}
.dropdown_item_button ::after {
  content: "";
}
.custom_dropdown_toggle {
  padding: 4px 6px !important;
  border: none;
}
.dropdown_css_item {
  color: #000 !important;
  padding: 0px 3px !important;
  font-size: 12px;
  font-weight: 550;
}
/* --------------------------------------------------- */
.table_info_css {
  color: var(--Dark, #212224);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  border: 1px solid white !important;
  text-align: right;
}
/* ------------tabs css----------------------------------- */
#fill-tab-example {
}
ul.nav.nav-tabs.nav-fill {
  /* border-radius: 4px 4px 0px 0px;
  background: rgba(255, 255, 255, 0.18); */
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  background: rgba(255, 255, 255, 0.18);
  padding: 8px 16px;
  /* opacity: 0.8; */
  color: var(--White, #fff);
  font-family: Inter;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black;
  background: #fff;
}
.service-table__container1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-left: 12px;
}
/* -----------------------copyicon------------------------------------ */
.copy_icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 48%;
  text-align: center;
  right: 8px;
  transform: translateY(-50%);
  display: none;
}
.hovered {
  background-color: #F0F6FF;
}

.accountCol:hover .copy_icon,
.copy_icon.visible {
  display: inline-block;
}
.copyMessage {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.table_row:hover .copy_icon,
.copy_icon.visible {
  display: inline-block;
}
/* ---------------------------------table css-------------------------------------------- */
#customers .table_data__Class,
#customers .th_class,
.total_Costs {
  width: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
  padding: 8px;
  /* color: var(--Neutral-D3, #495057); */
  color: rgba(33, 34, 36, 1);

  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #E9ECEF;
}
.table_td_width{
  width: 18% !important;
}

.tfooter_class {
  color: var(--Neutral-D3, #495057);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

#customers .table_data,
#customers .th_data {
  width: 70px;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
  text-align: right;
}
/* ------------------------------------------- */
#customers .table_data__Class1,
#customers .th_class1,
.total_Costs1 {
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
}
#customers .th_class {
  color: #343a40;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #f6f6f6);
  font-family: Inter;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#customers .th_data {
  background: var(--Header_bg, #f6f6f6);
  color: #000;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_table__container1{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-left: 12px;
}
.menu_space_between{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table_data__Class {
  max-width: 30px;
  /* max-width: 185px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

/* -------------------------------------------------------------- */
.box-shadow-left ::after {
}
/* table.maintable.td0 tr td table tr:nth-child(1) td, table.maintable.td1 tr td table tr:nth-child(2) td, table.maintable.td2 tr td  {
  background-color: yellow;
} */
/* .maintable {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
table {
  border: none;
}
table table {
  padding: 0;
  margin: -2px;
  border-collapse: collapse;
}


table.maintable tr td table tr td:hover:before {
  content: '';
  transform: translateY(-1em);
  height: 3.5em;
  background: var(--Controls-BG, #f0f6ff);
  display: inline-block;
  position: absolute;
  left: 2em;
  right: 2em;
  z-index: -1;
} */

.maintable {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

table {
  border: none;
}

table table {
  padding: 0;
  margin: -2px;
  border-collapse: collapse;
  /* width: 100%;  */
}

table.maintable tr td table tr td:hover:before {
  content: "";
  transform: translateY(-0.7em);
  height: 3.1em;
  background: var(--Controls-BG, #f0f6ff);
  position: absolute;
  left: 2em;
  right: 2em;
  z-index: -1;
}

@media (max-width: 768px) {
  .maintable {
    left: 0;
    transform: none;
  }

  table {
    width: 100%;
  }

  table.maintable tr td table tr td:hover:before {
    left: 0;
    right: 0;
  }
}
/* ----------------------------------two table css-------------------------------------------- */
.tableDiv {
  display: flex;
  flex-direction: column;
}

.tablesFilterDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 24px;
}
.recommendationsLabel {
  margin-top: 24px;
  color: #495057;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-right: 24px;
  line-height: normal;
}

.recommendationsText {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.13px;
  margin-bottom: 20px;
  padding-right: 24px;
}

.filterDiv {
  height: 100vh;
  border-left: 2px solid var(--neutral-d-7, #dee2e6);
}

.tableHead {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  background-color: #f6f6f6;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.accountCol {
  color: var(--Dark, #212224);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70px;
  font-family: Inter;
  overflow: hidden;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 8px;
  border-bottom:1px solid #E9ECEF;
}
.accountCol1 {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--Success-Text, #127e24);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  /* padding-left: 12px; */
  padding: 12px 8px;
}
.accountCol2 {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--Dark, #212224);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 8px;
}
.elipese {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 145px;
}
.border_bottom_none{
  border-bottom: none;
}

.accountTitleCol {
  max-width: 200px;
}
.alignment{
  text-align: right;
}

.accountTitleColData {
  cursor: pointer;
}

.two_table_container {
  display: flex;
  position: relative;
  width: 100%;
}

.scroll {
  width: 86%;
  overflow-x: scroll;
  
}
.scrollable .accountCol :hover{
  background-color:#F0F6FF;
}

.fixed {
  margin-right: 20px;
  display: flex;
  width: 13% !important;
  box-shadow: -25px -2px 11px -21px lightgrey;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  /* overflow-y: visible; */
}

.badge {
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--Success-Text, #127e24);
  border-radius: 4px;
  background: var(--Light-Green, #ebfaed);
}

.badgeDanger {
  background: #faebeb;
  color: #d01a1a;
}

.badgeWarning {
  background: #fdf6db;
  color: #5e5622;
}

.badgeOk {
  background: #ebfaed;
  color: #127e24;
}

.badgeNA {
  background: #e5e5e5;
  color: #1a1a1a;
}
 .snapshot_archived {
  color: #ed9615  !important;
}

 .snapshot_unarchived {
  color: #056ee5;
}

 .snapshot_col_center {
  text-align: center;
}
/* ------------------------------------------------- */
.second_div_third_container {
  background: #3da64f;
  border-radius: 0px 8px 8px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-bottom: 0px;
}
.innerContainer_1 {
  display: flex;
  padding: 16px 24px 12px 24px;
  gap: 20px;
  flex: 1;
}
.innerContainer_div {
  display: flex;
  gap: 8px;
  margin: 0px;
  padding: 0px;
}

.innerContainer_text {
  display: flex;
  gap: 0px;
  padding: 0px;
  flex-direction: column;
}

.innerContainer_text_1 {
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-align: uppercase;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer_text_2 {
  color: white;
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer_2 {
  background-color: #127e24;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex: 1;
}
.innerContainer_Box {
  background-color: #a71111;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex: 1;
}

.innerSecondContainer {
  display: flex;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  padding: 0px;
}

.innerSecondContainer_text {
  display: flex;
  color: white;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  /* word-wrap: break-word; */
  padding: 0px;
  width: 100%;
  align-items: center;
}

.innerSecondContainer_num {
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
/* --------------------------------------------------------------- */
.copy-icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  /* top: 40%; */
  text-align: right;
  /* margin-left: 20px; */
  /* right: 8px; */
  transform: translateY(-50%);
  display: none;
}

.accountCol:hover .copy-icon,
.copy-icon.visible {
  display: inline-block;
  text-align: right;
}
/* ---------------------------------------------------- */

#customers tbody :hover {
  /* background: var(--Controls-BG, #F0F6FF);  */
}
.cost-cell{
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transform: translate(30%, 0%);
}
.cloud-cell {
  /* width: calc(50% - 20px); */
  width: 100%;
  /* padding:5px; */
  color: var(--Background-module, #f0f0f0);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
 
}
.dropdown-item {
  color: #000 !important;
  padding: 4px 6px !important;
}

/* ------------------------filter section css --------------------------------- */
.filter_main_div {
  /* border-left: 1px solid var(--neutral-d-7, #dee2e6); */
  background: #fff;
  width: 100%;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box_inner {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css_inner {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.placeholder_text {
  padding: 4px 6px;
}
.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  width: 70px;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
}
/* --------------------------------------------------------------------------------- */

.mytabs_button button {
  background: rgba(255, 255, 255, 0.18);
  color: var(--White, #fff);
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

.mytabs_button button:hover {
  color: var(--White, #fff);
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid #FFFFFF2E!important;
}

.sorting_icon {
  top: 50%;
  right: 0;
  transform: translateY(-15%);
  padding-left: 6px;
  display: none;
}

.tableHead:hover .sorting_icon {
  display: inline-block;
}
.th_class:hover .sorting_icon {
  display: inline-block;
}









