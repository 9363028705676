.main_containers {
  position: absolute;
  /* left: 56px; */
  padding: 0px;
  width: calc(100vw - 56px);
  /* margin-top: 61px; */
  padding-bottom: 33px;
}

.sorting_icon {
  /* position: absolute; */
  top: 50%;
  right: 0;
  transform: translate(50%, -18%);
  display: none;
  /* padding-left: 10px; */
}

.snap_th_class:hover .sorting_icon {
  display: inline-block;
}

.main_container {
  display: flex;
  background: #0f2573;
  /* height: 235px; */
  padding: 16px 40px 16px 40px;
  gap: 16px;
  flex-direction: column;
}

.trhovered {
  background-color: #f0f6ff !important;
}

.Inner_main_Container {
  display: flex;
  /* background: red; */
  flex-direction: column;
  padding: 0px;
  height: 242px;
  gap: 16px;
}
.inner_first_container {
  gap: 16px;
  padding: 0px;
}
.inner_first_container_text {
  opacity: 0.5;
  color: white;
  font-size: 21px;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}
.inner_third_container {
  display: flex;
  gap: 10px;
}
.inner_contaier_text {
  color: white;
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  opacity: 0.5;
  justify-content: flex-end;
  display: flex;
  padding: 0px;
}

/* ------------------------------------------------- */
.snap_sec_div {
  width: 100%;
  border-radius: 8px;
  display: flex;
  background-color: white;
  margin: 0px;
}

.snapshots_by_types {
  width: 69%;
  padding: 16px 24px 16px 24px;
  display: flex;
  gap: 28px;
  background-color: white;
  border-radius: 8px 8px 8px 8px;
}

.snapshots_main {
  width: 11%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.snapshot_text {
  color: #797b80;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}

.snapshot_value {
  display: flex;
  align-items: center;
  color: #212224;
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
}

.snap_cost_text {
  color: #797b80;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
}

.snap_cost_value {
  color: #343a40;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
}

.snapshot_orphaned_color {
  color: #127e24;
}

.snapshot_age_main {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.value_on_age {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.age_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gap_value_container {
  display: flex;
  gap: 6px;
  width: 100%;
  align-items: center;
}

.days_gap {
  color: #343a40;
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
}

.values_cost {
  color: #212529;
  font-size: 14px;
  font-weight: 700;
  word-wrap: break-word;
}

.untagged_snapshot_main {
  width: 22%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.snapshots_by_cloud {
  width: 31%;
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f8f9fa;
  border-radius: 0px 8px 8px 0px;
}

.cloud_inner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.By_cloud_mainContianer {
  width: 100%;
  display: flex;
  gap: 40px;
  /* justify-content: space-between; */
}

.cloud_containers {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  border-right: 1px solid #dee2e6;
}
.cloud_containersGCP {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  /* border-right: 1px solid #dee2e6; */
}
.right_border {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* width: 50%; */
  border-right: none;
}

.cloudInner_contianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.snapshotCloud_value {
  color: #212224;
  font-size: 24px;
  font-weight: 700;
  word-wrap: break-word;
}

.cloud_value {
  color: #343a40;
  font-size: 16px;
  font-weight: 600;
  word-wrap: break-word;
}

@media only screen and (max-width: 1435px) {
  .snapshots_by_types {
    /* width: 100%; */
  }

  .snapshots_by_cloud {
    /* width: 100%; */
  }
}

/* bodySection----------------- */
.body_section {
  display: flex;
  padding-right: 40px;
  padding-bottom: 30px;
  /* gap: 23px; */
}

.Chart_table_section {
  padding-top: 19px;
  padding-left: 44px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.allCloud_Chart_table_section {
  width: 100%;
  padding-top: 19px;
  padding-left: 44px;
  /* padding-right: 40px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.snap_filter_section {
  width: 17%;
  height: 100vh;
  border-radius: 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.snap_chart_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.heading_text {
  color: #212224;
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
}
.heading_text_body {
  color: #212224;
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
}

.chart_containers {
  height: 230px;
  width: 100%;
  display: flex;
  gap: 24px;
}
.data_loading {
  padding: 0px !important;
}

.chart_container {
  /* height: 147%; */
  /* width: 32%; */
  width: 100% !important;
  padding: 16px 16px 24px 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.type_cost {
  width: 100%;
  display: flex;
  gap: 2px;
  justify-content: space-between;
}

.bownloadButton {
  height: 24px;
  padding: 4px 6px 4px 6px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #f0f6ff;
}
.bownloadButton:hover {
  cursor: pointer;
}

.snap_cloud_text {
  color: #212224;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
}

.cost_text {
  color: #495057;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}

.cost_value {
  color: #495057;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
}

.snap_table_section {
  width: 100%;
  display: flex;
}
.snap_cloud_th {
  font-size: 13px;
  font-weight: 400;
  color: #212224 !important;
}
.snap_cloud_foot_th {
  font-size: 13px;
  font-weight: 700;
  color: #212224 !important;
}
.snap_cloud_foot_td {
  font-size: 13px;
  font-weight: 700;
  color: #495057 !important;
}
.snap_detail_td {
  font-size: 13px;
  font-weight: 400;
  color: #495057;
}

.snap_th_class {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
  background-color: #f6f6f6 !important;
  border: none;
  /* position: relative; */
}
.snap_th_classmodal {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
  background-color: #E9ECEF !important;
  border: none;
  /* position: relative; */
}
.snap_th_class_center {
  text-align: center;
}
.snap_table_data__Class {
  color: #212224;
  font-size: 13px;
  font-weight: 500;
  word-wrap: "break-word";
  max-width: 212px;
  background: none !important;
  height: 20px;
}
#snap_dropdown_header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f !important;
  border: none;
}

#dropdown-item-button {
  border-radius: 4px;
  background: var(--Controls-BG, #f69e39);
  display: flex;
  border: none;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#dropdown_menu_item {
  padding: 8px;
  margin: 0px;
  transform: translate3d(-162px, 31.2px, 0px) !important;
  border: 1px solid #ccc8c8 !important;
}

.discardedDropdown {
  padding: 8px;
  margin: 0px;
  border: 1px solid #ccc8c8 !important;
  box-shadow: 0 19px 14px rgba(0, 0, 0, 0.1);
}

#dropdown-header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
  border: none;
}

#dropdown_item:hover {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}
#dropdown_item {
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
}

.body-table-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.snap_Heading_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* padding-right: 40px; */
}
.justified_space {
  display: flex;
  justify-content: space-between;
}
.menu_icon_css1 {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.snap_heading-detail {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.table_container {
  /* overflow: auto; */
  white-space: nowrap;
}

.container_secTable {
  /* width: 31%; */
  /* width: 31%; */
  position: relative;
  box-shadow: -25px -2px 11px -21px lightgrey;
}

.snapshot_archived {
  color: #ed9615;
}

.snapshot_unarchived {
  color: #056ee5;
}

.snapshot_col_center {
  text-align: center;
}

.snapshot_col_weight {
  font-weight: 600 !important;
}
.snapshot_col_end {
  text-align: end;
}
.snap_copy_icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: right;
  display: none;
  /* margin-left: 20px; */
}

.for_copy_hover:hover .snap_copy_icon,
.snap_copy_icon.visible {
  display: inline-block;
  text-align: center;
}

.snap_table_data__Class:hover .snap_copy_icon,
.snap_copy_icon.visible {
  display: inline-block;
  text-align: center;
}
.copyMessage {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.dropdown_toggle::after {
  content: none;
}
.table img,
svg {
  color: black;
}

.my-spinner {
  text-align: center;
  margin-left: 450%;
  margin-top: 15%;
  color: #056ee5;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.filter_group_name_css :hover {
  cursor: pointer;
}
.total_Costs {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

/* filter--------------- */

.untagged {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.untagged_class {
  display: flex;
  justify-content: start;
  gap: 8px;
}

.filter_main_div {
  background: #fff;
  width: 18%;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
  display: flex;
  padding: 16px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100vh;
  border-left: 1px solid #dee2e6;
  min-width: 0;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.table_info_css {
  color: var(--Dark, #212224);
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  border: 1px solid white !important;
  text-align: right;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box_inner {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css_inner {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  width: 70px;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}

/* .filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
} */

.filter_box_div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box_inner {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css_inner {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  width: 70px;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  white-space: nowrap;
  padding-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 176px;
}

/*{SNAPSHOT STATE CHANGE}*/

.modalContent {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  /* min-height: 350px; */
  /* height: 495px; */
  border-radius: 8px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-grow: 1;
}

.headerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.tittle {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tagline {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputFields {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  gap: 16px;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex: 1;
}

#AcceptId {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex: 1;
}
.cancleButton {
  border-radius: 4px;
  border: 1px solid var(--Neutral-D5, #adb5bd);
  background: var(--White, #fff);
  color: var(--Neutral-D4, #797b80);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
  height: 40px;
}

.submitButton {
  border-radius: 4px;
  background-color: #056ee5;
  border: 1px solid #056ee5;
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
  height: 40px;
}

.recHistoryTittle {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  cursor: pointer;
}

.recHistoryContainer {
  position: absolute;
  height: 100%;
  top: 0px;
  left: 442px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px) 0px;
  background: #f0f0f0;
  /* width: 100%; */
  /* min-height: 494px; */
  z-index: 1056;
}

.discardedContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px) 0px;
  background: #f0f0f0;
  background: linear-gradient(to bottom, white 67px, #f0f0f0 67px);
  width: 391px;
  position: relative;
}
.discardedHeaderSection {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}

.historyTittle {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.removeImage {
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.hideContainer {
  display: flex;
  width: 343px;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: none;
  background: var(--Controls-BG, #f0f6ff);
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
}

/* ---------------------------------- */
.hideContainer:hover img,
.hideContainer:active img {
  filter: invert(90) brightness(50);
}
.historyModal {
  height: 50vh;
}
/* --------------------------------------- */

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.detailContainers {
  display: flex;
  padding: var(--Corner-Radius-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Corner-Radius-8, 8px);
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e9ecef;
  background: rgba(248, 249, 250, 0.5);
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.acceptCreteriaDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.detailsComments {
  color: var(--Neutral-D3, #495057);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.translate {
  /* transform: translate(416px,-500px); */
}
.modalContantTranslateActive {
  position: relative;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  transform: translate(-80px);
}

.astrick {
  color: #d9352a;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.lableClass {
  color: var(--Neutral-D4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2px;
}
#inputId,
.inputClass {
  padding: 8px !important;
  border-radius: 4px;
  color: #000;
  border: 1px solid rgba(206, 212, 218, 1);
}

.discardedContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px) 0px;
  background: #f0f0f0;
  background: linear-gradient(to bottom, white 67px, #f0f0f0 67px);
  width: 391px;
  position: relative;
}
.discardedHeaderSection {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}

.historyTittle {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.removeImage {
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.hideContainer {
  display: flex;
  width: 343px;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Controls-BG, #f0f6ff);
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
}

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.detailContainers {
  display: flex;
  padding: var(--Corner-Radius-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Corner-Radius-8, 8px);
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e9ecef;
  background: rgba(248, 249, 250, 0.5);
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.acceptCreteriaDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.detailsComments {
  color: var(--Neutral-D3, #495057);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.translate {
  /* transform: translate(416px,-500px); */
}
.modalContantTranslateActive {
  position: relative;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  transform: translate(-80px);
}

.badgeName {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
.badgeName::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #adb5bd;
}

.badgeNamePending {
  background-color: #f1f5f7;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #2a546f;
}
.badgeNamePending::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #2a546f;
}

.badgeNameProgress {
  background-color: #ebf4fd;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #0058bd;
}
.badgeNameProgress::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #0058bd;
}
.badgeNameDone {
  background-color: transparent;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #127e24;
  border: 1px solid #dee2e6;
}

.mytabs_button button {
  background: rgba(255, 255, 255, 0.18);
  color: var(--White, #fff);
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.badgeNameDiscard {
  background-color: #fceded;
  padding: 0px 8px 0px 8px;
  border-radius: 4px;
  color: #ae0909;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.mytabs_button button:hover {
  color: var(--White, #fff);
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid #ffffff2e !important;
}

.errorMessage {
  font-size: 10px;
  color: brown;
  margin-top: 0px !important;
}

.ageRange {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.31px;
  text-align: left;
  color: #495057;
  margin-bottom: 4px;
}

.daysDifference {
  background-color: #e6eefa4d;
  color: #056ee5;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  width: 100%;
  padding: 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ecf2ff;
  margin-top: 24px;
  margin-bottom: 8px;
}

.minMax {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.slider-handle {
  background-color: #000 !important;
}

.minMaxText {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #495057;
  width: 100%;
}
/*  */
.applyButtonAge {
  /* padding: 0px; */
  height: 30px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-top: 13px;
}
.inputStyle {
  border-radius: 4px;
  border-color: #adb5bd;
  width: 100%;
  height: 28px;
}

.historyModal {
  display: flex;
  padding: 24px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #f0f0f0;
  z-index: 1056;
  align-items: center;
}
.dropdown_names_css {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #343a40;
}

.menuStatusContainer {
  display: flex;
  justify-content: space-between;
}
.res_type {
  display: flex;
  align-items: center;
  gap: 1px;
}
.res_type_text {
  /* opacity: 0.7; */
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 0px 16px 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Neutral-D9, #f8f9fa);
}
.dropdown_item {
  color: #000 !important;
  padding: 2px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
.dropdown_item:hover {
  border-radius: 4px !important;

  background: var(--Controls-BG, #f0f6ff) !important;
}
.dropdown_item_button ::after {
  content: "";
}
#dropdown_item_button {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  border: none;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* ----------------------------------------modal----------------------------------- */
.actionTypeHeader {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
}
.textSideHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.textBoxSEction {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.comment_box textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  resize: none;
  outline: none;
  box-shadow: none;
  transition: border-color 0.2s ease-in-out;
}

.comment_box textarea:focus {
  box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.TableSectionPart {
  background-color: #f8f9fa;
  /* height: 80vh; */
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.textAlignStart {
  text-align: start !important;
}
.textAlignend {
  text-align: end !important;
}
/* .containerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.placeholderStyle {
  width: 50px;
  height: 30px;
  border: 1px dashed #0078d7;
  border-radius: 4px;
} */

.buttonContainerStyle {
  border-top:1px solid#f8f9fa;
  display: flex;
  gap: 10px;
  justify-content: end;
  height: fit-content;
  width: 100%;
  padding: 16px 24px;
}

.cancelButtonStyle {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 4px;
  color: #757575;
  cursor: pointer;
  width: 132px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButtonStyle {
  padding: 8px 16px;
  border: 1px solid #0078d7;
  background: #0078d7;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  width: 132px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.stickyHeader th {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 2;
}

.trBAckground{
  background-color: #E6EEFA;
}
.disabledRow {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f0f0f0; /* Optional */
}

/* tooltip------------- */
.tooltip_tr_name {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  padding-left: 8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ced4da;
}
.tooltip_cloud_name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f0f0f0;
}
.tooltip_tr_value {
  color: #f8f9fa;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.tooltip_row:not(:last-child) {
  border-bottom: 1px solid #373737;
}
.tooltip_table_flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}
.display_icon {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.tooltip_date_css {
  color: var(--neutral-d-7, #dee2e6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
}
.tooltip_date {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: #2c2c2c;
  padding-bottom: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  border-bottom: 1px solid #373737;
}
.custom_tooltip table {
  width: 100%;
}

.custom_tooltip th {
  color: white;
  padding: 8px;
  align-items: flex-start;
  color: var(--nn-4, #a09fa5);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.custom_tooltip td {
  padding: 8px;
  color: white;
  align-items: flex-start;
}
