.containerPadding{
    padding: 24px 20px 24px 20px;
}

.table_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .td {
    padding: 12px;
    border: 1px solid #DEE2E6;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    /* display: flex;
    flex-direction: column; */
    /* width: 100%; */
  }

  
  @media (max-width: 768px) {
    .table_container {
      padding: 10px;
    }
  
    .table {
      width: 100%;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      
    }
  
    .td {
      font-size: 12px;
      padding: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .td {
      display: block;
      width: 100%;
      text-align: left;
    }
  
    .td:nth-child(odd) {
      background: #f1f1f1;
    }
  }
  .subname{
    color: #212224;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .label{
    font-size: 13px;
    font-weight: 400;
    color: #495057;
  }
  .tdOnly{
    display: flex;
    flex-direction: column;
  }