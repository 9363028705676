.tableDiv {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 80%;
  gap: 8px;
  gap: 8px;
}
.dismiss_css {
  color: red !important;
}
.downloadButton{
  background: #E6EEFA;
  width: 26px;
  /* text-align: center; */
  border-radius: 4px;
  /* height: 30px; */
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

}
.tableDiv1 {
  border-right: 1px solid var(--neutral-d-7, #dee2e6);

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 84%;
  gap: 8px;
  gap: 8px;
  padding-right: 24px;
  height: 100vh;
}
.upperContent_texts {
  padding: 0px;
  color: #797b80;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}

.tablesFilterDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 40px;
}

.recommendationsLabel {
  margin-top: 24px;
  color: #495057;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* padding-right: 24px;
  line-height: normal; */
}

.recommendationsText {
  color: #495057;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  margin-bottom: 12px;
}

.filterDiv {
  height: 100vh;
  border-left: 2px solid var(--neutral-d-7, #dee2e6);
}

.tableHead {
  color: #343a40 !important; 
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  background-color: #f6f6f6;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.my_white_space{
  white-space: nowrap;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#table_body_tr :hover {
  background-color: #f0f6ff;
}

.accountCol {
  max-width: 100%;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
  border-bottom: 1px solid #e9ecef;
}

.downArrow {
  border: none;
  border-radius: 100px;
  background-color: rgba(61, 166, 79, 0.1);
  padding: 4px 5px 3px 5px;
}
.upArrow {
  border: none;
  border-radius: 100px;
  background-color: rgba(250, 235, 235, 1);
  padding: 4px 5px 3px 5px;
}
.Border_none {
  border-bottom: none !important;
}

.accountCol_end {
  text-align: end !important;
}
.accountCol_center {
  text-align: center ;
}
.accountCol_red {
  color: #d01a1a !important;
}
.accountCol_green {
  color: #127e24 !important;
}
.accountCol_weight {
  font-weight: 600;
}
.dropdown_menu {
  position: absolute !important;
  inset: 0px auto auto 24px !important;
  transform: translate3d(-154.4px, 25.6px, 0px) !important;
}

.accountColNowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accountTitleCol {
  max-width: 250px;
}
.lowerContent_icon {
  display: flex;
  height: 12px;
  gap: 7.5px;
  /* width: 12px; */
  padding: 0px;
}

.accountTitleColData {
  cursor: pointer;
}

.percentageBadgeWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.percentageBadgeCont {
  width: 75px;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
}

.scroll {
  width: 79%;
  overflow-x: scroll;
}

.fixed {
  /* margin-right: 20px; */
  display: flex;
  width: 22% !important;
  box-shadow: -25px -2px 11px -21px lightgrey;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 15px;
  background-color: white;
  /* overflow-y: visible; */
}
.tabs {
  background: rgba(255, 255, 255, 0.18);
  padding: 8px 16px;
  opacity: 0.8;
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tabs :active {
  color: black;
  background: #fff;
}

.badge {
  padding: 2px 4px;
  border-radius: 4px;
}

.badgeDanger {
  background: #faebeb;
  color: #d01a1a;
}

.badgeWarning {
  background: #fdf6db;
  color: #5e5622;
}

.badgeOk {
  background: #ebfaed;
  color: #127e24;
}

.badgeNA {
  background: #e5e5e5;
  color: #1a1a1a;
}

.accountTitleCol {
  max-width: 250px;
}

.accountTitleColData {
  cursor: pointer;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
}

.tabs {
  background: rgba(255, 255, 255, 0.18);
  padding: 8px 16px;
  opacity: 0.8;
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tabs :active {
  color: black;
  background: #fff;
}

.badge {
  padding: 2px 4px;
  border-radius: 4px;
}

.badgeDanger {
  background: #faebeb;
  color: #d01a1a;
}

.badgeWarning {
  background: #fdf6db;
  color: #5e5622;
}

.badgeOk {
  background: #ebfaed;
  color: #127e24;
}

.badgeNA {
  background: #e5e5e5;
  color: #1a1a1a;
}

.sidebar_modal {
  width: 100%;
  max-width: 884px;
}

.recommendationHeader {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.batch1 {
  margin: 0px;
  padding: 8px 12px;
  border-radius: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: rgba(61, 166, 79, 1);
  background-color: rgba(235, 250, 237, 1);
}

.batch {
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0px;
  padding-bottom: 4px;
}
/* .modal_body{

} */

.modal_body::-webkit-scrollbar {
  display: none;
}

.modal_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 12px; /* Firefox */
}
.recommendationText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* gap: 8px; */
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  padding-bottom: 8px;
}
.recommendationText .extension {
  color: #056ee5;
}

.recommendationTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  padding: 4px 0px 4px 0px;
  /* line-height: 0px; */
}
.headerText {
  color: #606367;
  text-align: start;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  text-transform: uppercase;
  padding-bottom: 4px;
}

.tableHeadRec {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 4px;
  color: #606367;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  background-color: #ebfaed;
  margin: 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
thead,
tbody,
td,
th {
  padding: 0px;
  margin: 0px;
}

.tableHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  padding-top: 12px;
  color: #606367;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  /* line-height: 0px; */
}
.tittle {
  color: #343537;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 18px */
}

.saving {
  padding-top: 0px;
  color: #343537;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  line-height: 120%;
}

.recInfoSpec {
  text-align: start;
  padding: 8px;
  margin: 0px;
  color: #343537;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}

.recSpec {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #f6f6f6;
  color: #343537;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}

.recProjectedValues {
  border-bottom: 1px solid rgba(61, 166, 79, 0.1);
  font-weight: 400;
  font-size: 13px;
}

.saving {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  padding-left: 12px;
  color: #fff;
  border-radius: 8px;
  margin: 0px;
  border-bottom: 1px solid #359446;
}

.riContainer {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--Corner-Radius-8, 8px);
  border: 1px solid var(--Neutral-D8, #e9ecef);
  background: var(--White, #fff);
}

.riDropDown {
  display: flex;
  width: 100%;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #f0f6ff;
}
.showless_more {
  color: #056ee5;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  word-wrap: break-word;
  gap: 4px;
  display: flex;
}
.showless_more:hover {
  cursor: pointer;
}
.savemore_text {
  color: #212224;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}
.riText {
  color: #212224;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-bottom: 10px; */
}
.riTableHeader {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 120%; 15.6px */
}
.riInstance {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}
.riInactive {
  display: none;
}
.riTd {
  padding: 4px 12px 4px 12px;
  color: #343537;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.riFooter {
  margin: auto;
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 13.2px */
}

.backgroundDownsize {
  background: #ebfaed;
}
.backgroundUpsize {
  background: rgba(208, 26, 26, 0.05);
}
.backgroundUpsizeHighlight {
  background: #d01a1a;
}
.backgroundDownsizeHighlight {
  background: #3da64f;
}

.dangerBatch {
  background: rgba(208, 26, 26, 0.05);
  color: #d01a1a;
}
.loading {
  height: 500px;
  margin: auto;
}
.currunt_cost {
  color: #212224;
  font-size: 32px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  width: auto;
}

.currunt_cost1 {
  color: #495057;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
}

.rec_inner_first_container {
  /* height: 25px; */
  gap: 16px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}
.Rec_inner_contaier_text {
  color: white;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  opacity: 0.5;
  justify-content: flex-end;
  display: flex;
  padding: 0px;
}
.rec_inner_second_container {
  display: flex;
  background-color: white;
  border-radius: 8px;
  margin: 0px;
}
.rec_number_container {
  color: #212224;
  font-size: 44px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}
.rec_details_first {
  display: flex;
  padding: 16px 16px 16px 24px;
  gap: 24px;
  background-color: white;
  border-radius: 8px;
  width: 21%;
}
.rec_details_sec {
  display: flex;
  padding: 16px 16px 16px 24px;
  gap: 24px;
  width: 30%;
}

.hovered {
  background-color: #f0f6ff;
}
.rec_details_third {
  display: flex;
  padding: 16px 16px 16px 24px;
  gap: 7px;
  background-color: #f8f9fa;
  flex-direction: column;
  /* width: 25%; */
}
.reclowerContent_numbers {
  color: #212224;
  font-size: 32px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}
.rec_ideal_resource_count_number {
  gap: 8px;
  padding: 0px;
  margin: 0px;
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  justify-content: flex-start;
}
.project_cost_text {
  color: #797b80;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}
.current_costsStyling {
  padding: 8px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #e9ecef99;
}

.project_cost {
  color: #343a40;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  word-wrap: break-word;
  padding: 0px;
  display: flex;
  width: auto;
  gap: 2px;
}
.recinnerSecondContainer {
  display: flex;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
}
.greenColor {
  background-color: #3da64f !important;
}
.darkgreenColor {
  background-color: #127e24 !important;
}
.redColor {
  background-color: #d01a1a !important;
}
.darkredColor {
  background-color: #a71111 !important;
}

.reclowerContent_texts {
  padding: 0px 0px 1px 0px;
  gap: 4px;
  display: flex;
  margin-top: 23px;
}
.project_cost1 {
  color: #343a40;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
  display: flex;
  align-items: center;
}
.include_down_up_text {
  color: #495057;
  font-size: 11px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  width: 100%;
}

.rec_content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 0px;
  border-right: 1px solid #e9ecef;
}
.rec_lowerContent {
  display: flex;
  gap: 4px;
  padding: 0px;
  /* margin-left: 9px; */
}
.rec_saving_text {
  color: #797b80;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}
.rec_saving_cost {
  color: #127e24;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: "break-word";
  padding: 0px;
}
.savings_container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.rec_second_div_third_container {
  background: #3da64f;
  border-radius: 0px 8px 8px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 24%;
}
.custom_select {
  position: relative;
}

.custom_select select {
  padding: 6px 10px 7px 8px;
  border-radius: 4px;
  color: white;
  background-color: #101d4f;
  width: 190px;
}

.custom_select select::-ms-expand {
  display: none;
}

.dropdown_toggle::after {
  content: none;
}

#dropdown_item:hover {
  border-radius: 4px !important;
  background: var(--Controls-BG, #f0f6ff) !important;
  /* width: calc(100% - 4px); */
}
.custom_select:after {
  /* content: "\1F893"; */
  position: absolute;
  top: 68%;
  right: 10px;
  width: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  color: white;
}

.custom_select select option {
  background-color: white;
  color: black;
  padding: 40px;
}

option:hover {
  background-color: #add8e6;
  color: #fff;
}
.rec_second_div_first_container_firstDiv {
  display: flex;
  flex-direction: column;
  height: 74px;
  gap: 8px;
}
.up_down_header_text {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}
#right_dropdown_header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
  border: none;
}
#dropdown_item:hover {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  /* width: calc(100% - 4px); */
}
#dropdown_menu_item {
  padding: 0px;
  margin: 0px;
  border: none;
  background: transparent;
}

#dropdown_basic_check {
  font-size: 13px;
  background: transparent;
  color: black;
}

.custom_dropdown_toggle::after {
  content: none;
}
.ChartSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  padding-left: 40px;
  padding-right: 19px;
}

.inner_first_container {
  gap: 16px;
  padding: 0px;
}
.inner_first_container_text {
  opacity: 0.5;
  color: white;
  font-size: 21px;
  font-family: inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}
/* -----------------------copyicon------------------------------------ */
.copy_icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 48%;
  text-align: center;
  right: 8px;
  transform: translateY(-50%);
  display: none;
}
.copy_icons{
  background: none;
  border: none;
  /* cursor: pointer; */
  /* outline: none; */
  position: absolute;
  /* top: 48%; */
  /* text-align: center; */
  /* right: 8px; */
  /* transform: translateY(-50%); */
  display: none;
}

.accountCol:hover .copy_icon,
.copy_icon.visible {
  display: inline-block;
}
.accountCol:hover .copy_icons,
.copy_icons.visible {
  display: inline-block;
}
.copyMessage {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

/* filter------------------- ----------------------------------------------------------------------------*/
.filter_main_div {
  background: #fff;
  width: 18%;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 16px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}

.btn__Container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.open_status {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
}
.open_status_color {
  color: #797b80;
}
.pending_status_color {
  color: #2a546f;
}

.status_gap {
  display: flex;
  gap: 4px;
  border-radius: 4px;
}
.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box_inner {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css_inner {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  width: 70px;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  white-space: nowrap;
  padding-right: 4px;
  overflow: hidden;
  max-width: 155px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy_icon_div:hover .my_icon,
.my_icon.visible {
  display: inline-block;
  text-align: center;
}

/* .copy_icon_container:hover .copy_icon_css {
  visibility: visible;
} */
.table_css_grid .ag-row {
  z-index: 0;
  &.ag-row-focus {
    z-index: 1;
  }
}
.table_css_grid .ag-row-hover {
  background-color: red !important;
}

.remove_icon:hover path {
  stroke: #d01a1a;
}
.mytabs_button button {
  background: rgba(255, 255, 255, 0.18);
  color: var(--White, #fff);
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

.mytabs_button button:hover {
  color: var(--White, #fff);
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid #FFFFFF2E!important;
  
}


/* -----------state change css---------------------------------------- */
.discarded_button {
  border: none;
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  background: #fceded;
  color: #ae0909;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
}
.badgeName {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797B80;
}
.badgeName::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #adb5bd;
}

/*STATES STYLING*/

.badgeNamePending {
  background-color: #f1f5f7;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #2a546f;
}
.badgeNamePending::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #2a546f;
}

.badgeNameProgress {
  background-color: #ebf4fd;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #0058bd;
}
.badgeNameProgress::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #0058bd;
}
.badgeNameDone {
  background-color: transparent;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #127E24;
  border: 1px solid #DEE2E6;
}
#dropdown-header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
  border: none;
}

#dropdown_item:hover {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}
#dropdown_item {
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 12px;
}
#dropdown_menu_item {
  padding: 8px;
  margin: 0px;
  /* transform: translate3d(-162px, 31.2px, 0px) !important; */
  border: 1px solid #ccc8c8 !important;
}
.dropdown_toggle::after {
  content: none;
}
.dropdown_names_css{
  font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 14.52px;
text-align: left;
color: #343A40;
}

.sorting_icon {
  /* position: absolute; */
  top: 50%;
  right: 0;
  transform: translate(50%, -10%);
  display: none;
}

.tableHead:hover .sorting_icon {
  display: inline-block;
}



