.service_table__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 12px;
  width: 99.5%;
}

.inner__container_of_service_table {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

#customers {
  font-family: Arial, Helvetica;
  border-collapse: collapse;
  width: 100%;
}
.menu_icon_accept {
  border-width: 0px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #f6f6f6;
  color: var(--Dark, #212224);
}
.inset_css{
  inset: 0px auto auto -140px !important;
}

#customers .table_data__Class,
#customers .th_class,
.total_Costs {
  width: 100px;
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
}
#customers .table_cost_paid {
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Success-Text, #127E24);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
  background: transparent;
}
.status_table {
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  text-align: left;
  border-bottom: 1px solid #f6f6f6;
}
.highlighted_row {
  background-color: #e6eefa !important;
}

.tfooter_class {
  color: var(--Neutral-D3, #495057);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
.table_td_width {
  width: 15% !important;
}
.table_td_width1 {
  width: 20% !important;
}

#customers .table_data,
#customers .th_data {
  width: 70px;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
  text-align: right;
}
/* ------------------------------------------- */
/* .sorting_icon {
  float: center;
  padding-left: 12px;
}
.th_class:hover .sorting_icon .sorting_icon.visible {
  display: inline-block;
} */
/* CSS */
.th_class {
  position: relative;
}

.sorting_icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: none;
}

.th_class:hover .sorting_icon {
  display: inline-block;
}

#customers .table_data__Class1,
#customers .th_class1,
.total_Costs1 {
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
}
#customers .th_class {
  color: #343a40;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #edeff2);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.input_checkbox_form input[type="checkbox"] {
  border: 1px solid #adb5bd;
  width: 16px;
  height: 16px;
}
.input_checkbox_form input[type="radio"]:focus {
  outline: 1px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

#customers .menu_head {
  color: #343a40;
  padding: 8px;
  align-items: center;
  /* background: var(--Header_bg, #f6f6f6); */
  background-color: #edeff2;
  border: none;
}

#customers .th_data {
  background: var(--Header_bg, #f6f6f6);
  color: #000;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.total_Costs {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.saving_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
}
.saving_down_space {
  display: flex;
  justify-content: space-between;
}

.saving_name_text {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main_resource_container {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
}

.resource_container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.res_type {
  display: flex;
  align-items: center;
  gap: 1px;
}

.res_type_text {
  opacity: 0.7;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 0px 16px 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Neutral-D9, #f8f9fa);
}

.all_checkbox {
  display: flex;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}

.all_checkbox_text {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkbox_img {
  width: 8px;
  height: 4px;
  fill: var(--Dark, #212224);
}
.table_info_css {
  color: var(--Dark, #212224);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  border: 1px solid white !important;
  text-align: right;
}
.selected_box_option {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}
.sselected {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #212529;
}
.selected_number {
  color: #797b80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.checkbox_seleted_count {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}
.accept_jira_button {
  padding: 6px 12px 6px 12px;
  gap: 4px;
  border-radius: 4px;
  background-color: #f8f9fa;
  border: none;
  color: #adb5bd;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* -----------------------table hover css---------------------------------------- */

.table-hover thead tr:hover th,
.table-hover tbody tr:hover td {
  background: var(--Controls-BG, #f0f6ff);
}

/* ---------------------------idle resource header css------------------------------------ */
.mytabs_button button {
  background: rgba(255, 255, 255, 0.18);
  color: var(--White, #fff);
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mytabs_button button:hover {
  color: var(--White, #fff);
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid #ffffff2e !important;
}
.main_container {
  display: flex;
  background: #0f2573;
  padding: 16px 40px 0px 40px;
  gap: 16px;
  flex-direction: column;
}

.Inner_main_container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
}

.inner_first_container {
  height: 25px;
  gap: 16px;
  padding: 0px;
}

.inner_first_container_text {
  opacity: 0.5;
  color: white;
  font-size: 21px;
  font-family: inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

.inner_second_container {
  padding: 0px;
}

.second_div_first_container {
  background-color: #ffffff;
  padding: 16px 24px 16px 24px;
  gap: 24px;
  border-radius: 8px 0px 0px 8px;
}

.second_div_first_container_firstDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.ideal_resource_count {
  color: #797b80;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

.number_container {
  margin: 0px;
  color: #212224;
  font-size: 40px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}
.number_container1 {
  color: #212224;
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}
.css_for_gap {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.percent_container {
  display: flex;
  gap: 10px;
  padding: 0px 0px 7px 0px;
}

.percent_container_first_div {
  gap: 4px;
  display: flex;
  padding: 0px;
}

.second_div_first_container_secDiv {
  border-left: 1px solid #dee2e6;
  height: 120px;
}

.second_div_first_container_ThirdDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px;
}

.Idle_duration {
  color: #797b80;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}

.Idle_duration_container {
  height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.Idle_duration_number {
  color: #495057;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}

.Idle_duration_content {
  display: flex;
  width: 50px;
  height: 16px;
  gap: 4px;
}

.second_div_second_container {
  background-color: #f8f9fa;
  padding: 16px 24px 0px 24px;
  gap: 8px;
}

.second_div_second_main_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px;
}

.text_container {
  color: #797b80;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 0px;
  margin-left: 12px;
}

.content_container {
  padding: 0px;
}

.content_container_box {
  display: flex;
  padding: 0px;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.content_container_div {
  display: flex;
  padding: 0px;
}

.content_container-first {
  display: flex;
  gap: 16px;
  padding: 0px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 0px;
}

.upperContent {
  display: flex;
  gap: 8px;
  height: 17px;
  padding: 0px;
}

.upperContent_text {
  color: #797b80;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
  text-wrap: nowrap;
}

.lowerContent {
  display: flex;
  height: 28px;
  gap: 4px;
  padding: 0px;
}

.Kuber_lowerContent {
  display: flex;
  height: 28px;
  gap: 4px;
  padding: 0px;
  margin-left: 23px;
}

.lowerContent_number {
  color: #212224;
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  width: 33px;
}

.lowerContent_text {
  height: 17px;
  padding: 0px 0px 1px 0px;
  gap: 4px;
  display: flex;
  margin-top: 10px;
}

.lowerContent_text-1 {
  color: #797b80;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}

.lowerContent_icon {
  display: flex;
  height: 12px;
  gap: 7.5px;
  padding: 0px;
}

.second_div_third_container {
  background: #3da64f;
  border-radius: 0px 8px 8px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 0px;
}

.third_box_container {
  background: #d01a1a;
  border-radius: 0px 8px 8px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 0px;
}

.innerContainer_1 {
  display: flex;
  padding: 16px 24px 12px 24px;
  gap: 20px;
  flex: 1;
}
.adding_margin_bottom {
  margin-top: -40px;
}

.innerContainer_div {
  display: flex;
  gap: 8px;
  margin: 0px;
  padding: 0px;
}

.innerContainer_text {
  display: flex;
  gap: 8px;
  padding: 0px;
  flex-direction: column;
}

.innerContainer_text_1 {
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-align: uppercase;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer_text_2 {
  color: white;
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer_2 {
  background-color: #127e24;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex: 1;
}
.innerContainer_Box {
  background-color: #a71111;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex: 1;
}

.innerSecondContainer {
  display: flex;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  padding: 0px;
}

.innerSecondContainer_text {
  display: flex;
  color: white;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  /* word-wrap: break-word; */
  padding: 0px;
  width: 100%;
  align-items: center;
}

.innerSecondContainer_num {
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.inner_third_container {
  display: flex;
  gap: 10px;
}

.inner_contaier_text {
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  opacity: 0.5;
  justify-content: flex-end;
  display: flex;
  padding: 0px;
}

.IdleResource_head {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.IdleResource_heading {
  color: #212224;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}
.cost_block {
  display: flex;
  align-items: center;
  gap: 4px;
}
.costs_name_css {
  color: var(--Neutral-D4, #797b80);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  /* line-height: 120%; */
}
.cost_value_css {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
}
.cost_value_count {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
}
/* ----------------------------css for copy icon in the table------------------------------- */
.copy_icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 45%;
  text-align: center;
  right: 8px;
  transform: translateY(-50%);
  display: none;
}

.table_row:hover .copy_icon,
.copy_icon.visible {
  display: inline-block;
}

.copyMessage {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.selected_rows {
  font-weight: 600;
  font-family: inter;
  font-size: 15px;
  line-height: 18.15px;
}
.selected_rows_count {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.accept_dismiss_selected {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
}

/* --------------------------------dropdown css ------------------------------------ */
#dropdown_item_button {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  border: none;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#dropdown_menu_item {
  padding: 0px;
  margin: 0px;
  border: none;
  background: transparent;
}

.dropdown_menu_item {
  padding: 0px;
  margin: 0px;
  border: none;
  background: transparent;
}

#dropdown_header {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 6px 10px 7px 11px;
  gap: 10px;
  border-radius: 4px;
  background-color: #101d4f;
  border: none;
}
#dropdown_basic_check {
  font-size: 13px;
  background: transparent;
  color: black;
}

.dropdown_item:hover {
  border-radius: 4px !important;

  background: var(--Controls-BG, #f0f6ff) !important;
}
.dropdown_menu {
  padding: 8px 8px !important;
  /* inset: -6px 20px 5px -11px !important;
  z-index: 2 !important; */
}
.dropdown_item {
  color: #000 !important;
  padding: 2px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
.custom_dropdown_toggle::after {
  content: none;
}
.dropdown_item_button ::after {
  content: "";
}
.custom_dropdown_toggle {
  padding: 4px 6px !important;
  border: none;
}
.dropdown_css_item {
  color: #000 !important;
  padding: 0px 3px !important;
  font-size: 12px;
  font-weight: 550;
}

/* ------------------------------bootstrap tooltip css in idle resource-------------------------------- */
.tooltip_name {
  opacity: 0.8;
  color: var(--Neutral-D7, #dee2e6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.tooltip_css_boot {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #2c2c2c;
}
.td_data {
  color: var(--Neutral-D6, #ced4da);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* ------------------------------menu icon css-------------------------------------------- */
.menu_icon_css1 {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.dismiss_css {
  color: rgba(208, 26, 26, 0.9) !important;
}
#dropdown-region-button {
  border-radius: 4px;
  display: flex;
  border: none;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: var(--Neutral-D6, #ced4da);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
/* ------------------------------------------------------- */
.table_data__Class {
  max-width: 30px;
  /* max-width: 185px; */
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  position: relative;
}

.dropdown_toggle::after {
  content: none;
}
.adding_border_line {
  border-right: 1px solid var(--neutral-d-7, #dee2e6);
  box-shadow: 1px 0 0 0 #0000000a;
  z-index: 0;
}
.deleted_reource {
  color: #797B80;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
}

/* -----------------------------------pie chart css-------------------------------- */

.cost_graph {
  height: 80%;
  display: flex;
  padding: 16px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
  margin-bottom: -24px;
}
.aws_costs_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 5px;
}
.aws_cost_name1 {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.aws_cost_value {
  color: #212224;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}
.aws_cost_text {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.table_value_css {
  font-size: 15px;
  font-weight: 700;
  color: #000000;
}
/* ----------------------bargraph------------------------------------- */
.all_cloud_text {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.last_days_name {
  margin-left: 4px;
  color: var(--neutral-d-5, #adb5bd);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}
.icc_main_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.graph_main_div {
  display: flex;
  justify-content: flex-start;
  gap: 16px !important;
  margin-left: -8px;
}

.aws_cost_name2 {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.aws_cost_value {
  color: #495057;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}
.buttons_css {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.aws_cost_text_div {
  padding: 0px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.aws_cost_text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
/* -----------------------------bargraph tooltip-------------------------------------- */
.custom_tooltip {
  background-color: black;
  border: 1px solid #000;

  border-radius: 8px;
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.custom_tooltip table {
  width: 100%;
}

.custom_tooltip th {
  color: white;
  padding: 8px;
  align-items: flex-start;
  color: var(--nn-4, #a09fa5);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cloud_cell {
  /* width: calc(50% - 20px); */
  width: 100%;
  color: var(--Background-module, #f0f0f0);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
tr.horizontalline_tool {
  border-bottom: 1px solid #373737;
  width: 100%;
  padding-left: 24px;
}

.spacer_cell {
  width: 55px;
}

.cost_cell {
  /* width: calc(50% - 20px); */
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transform: translate(30%, 0%);
}

.custom_tooltip td {
  padding: 8px;
  color: white;
  align-items: flex-start;
}
.cloud_logo {
  width: 16px;
  height: 16.5px;
  flex-shrink: 0;
  margin-right: 8px;
  align-items: flex-end;
}
.tooltip_date_css {
  color: var(--neutral-d-7, #dee2e6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
}
.tooltip_date {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: #2c2c2c;
  padding-bottom: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  border-bottom: 1px solid #373737;
}
/* -------------------------------------------indvidualCostGraph.css-------------------------------------------------------- */
.all_cloud_text1 {
  color: #495057;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.last_days_name1 {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding-top: 4px;
}
.aws_cost_name {
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.aws_cost_value1 {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.buttons_css {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.no_data_css {
  color: var(--Neutral-D4, #797b80);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
  transform: translate(0, -10%);
}

/* ------------------------filter section css --------------------------------- */
.filter_main_div {
  /* border-left: 1px solid var(--neutral-d-7, #dee2e6); */
  background: #fff;
  width: 100%;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
  display: flex;
  padding: 16px 8px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}
.untagged {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.untagged_class {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-left: 7px;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.disabled_title {
  color: #adb5bd;
}

/* ---------------------------------------------------------- */

/* ---------------------------------------------------------- */

.filter_grouping_box {
  display: flex;
  padding: 10px 8px;
  align-items: center;
  /* height: 20%; */
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: var(--White, #fff);
  background-image: url("@/assets/img/select_minor.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}
.filter_group_name_css {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: visible;
}
.optionContainer::-webkit-scrollbar {
  display: none;
}
.searchWrapper {
  border: none !important;
  width: 80%;
}
.chip.false {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  margin-bottom: 2px;
  padding: 4px;
  width: 122%;
  text-overflow: ellipsis;
}
.chip,
.singleChip {
  white-space: wrap !important;
}

.chip_text {
  min-width: 0;
}

.multiselectContainerReact {
  margin-left: 0px;
}

.multiselectContainer li:hover {
  background: var(--Controls-BG, #f0f6ff) !important;
  color: white;
  cursor: pointer;
}
.multiSelectContainer li {
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}
.searchBox {
  border: none;
  color: var(--neutral-d-5, #adb5bd);
  border-radius: 4px;
  width: 100%;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.closeIcon {
  background-color: black;
  border-radius: 16px;
  color: black;
}
.inputField {
  margin: 5px;
}
.table_css_grid {
  /* disable all borders */
  --ag-borders: none !important;
  height: 50vh !important;
  width: 100%;
  --ag-grid-size: 5px !important;
  --ag-list-item-height: 20px !important;
}
.copy_icon_container {
  position: relative;
}

.copy_icon_css {
  visibility: hidden;
  position: absolute;
  right: -20px;
}
.my_icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: right;
  display: none;
}

.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box_inner {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css_inner {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.elipese {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 145px;
  /* border: 1px solid #000000; */
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  width: 70px;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  max-width: 167px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .copy_icon_div:hover .my_icon,
.my_icon.visible {
  display: inline-block;
  text-align: center;
} */

/* .copy_icon_container:hover .copy_icon_css {
  visibility: visible;
} */
.table_css_grid .ag-row {
  z-index: 0;
  &.ag-row-focus {
    z-index: 1;
  }
}
.table_css_grid .ag-row-hover {
  background-color: red !important;
}

.remove_icon:hover path {
  stroke: #d01a1a;
}
.discarded_button {
  border: none;
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  background: #fceded;
  color: #ae0909;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
}
.badgeName {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
.jirastatus {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
.badgeName::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #adb5bd;
}

/*STATES STYLING*/

.badgeNamePending {
  background-color: #f1f5f7;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #2a546f;
}
.badgeNamePending::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #2a546f;
}

.badgeNameProgress {
  background-color: #ebf4fd;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #0058bd;
}
.badgeNameProgress::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #0058bd;
}
.badgeNameDone {
  background-color: transparent;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #127e24;
  border: 1px solid #dee2e6;
}
.dropdown_values {
  color: #212529;
  font-weight: 500;
  font-size: 12px;
  font-family: Inter;
}
.dropdown_names_css {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #343a40;
}
.ticket_status {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
/* Universal styling for status */
.status {
  width: 15%;
}
.status.in-progress {
  background-color: #ebf4fd;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #0058bd;
}
.status.in-progress::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #0058bd;
}
.status.open {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
.status.open::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #adb5bd;
}

.status.deleted {
  background-color: transparent;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #127e24;
  border: 1px solid #dee2e6;
}
.status.deleted::before {
  content: url("@/assets/img/doneStateChange.svg");
  display: inline-block;
  width: 13px;
  height: 13px;
  /* margin-right: 8px; */
}
/* --------------------------------------history page---------------------------------------------- */
.idle_history_page {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  /* padding-right: 24px; */
}
.history_filter {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* gap: 24px; */
  /* margin: 24px 40px 32px 40px; */
}
/* ----------------------------------------history bar graph tooltip--------------------------------------- */
.tooltip_tr_name {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  padding-left: 8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ced4da;
}
.tooltip_cloud_name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f0f0f0;
}
.tooltip_tr_value {
  color: #f8f9fa;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.tooltip_row:not(:last-child) {
  border-bottom: 1px solid #373737;
}
.tooltip_table_flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}
.display_icon {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
/* ---------------------------------------history header------------------------------------------------------------ */
.header_dropdown {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.select_period_css {
  color: #f8f9fa;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.5;
  line-height: 15.73px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.cal_dropdown {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
#calendar_dropdown {
  background-color: #101d4f;
  padding: 6px 10px 7px 11px;
  gap: 10px;
  border-radius: 4px;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: none;
}
/* --------------------------------dropdown calendar----------------------------------------- */
/* Styles for dropdown */
.dropdown_custom {
  position: relative;
  display: inline-block;
}

.dropdown_custom_button {
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  background-color: #101d4f;
  padding: 6px 10px 7px 11px;
  gap: 10px;
  border-radius: 4px;
  border: none;
}

.dropdown_menuss {
  position: absolute;
  /* top: 100%; */
  /* left: -185px; */
  /* width: 100%; */
  right: 0px;
  width: 300px;
  z-index: 1;
  box-shadow: 0px 7px 16px 0px #0000001a;
  box-shadow: 0px 64px 39px 0px #0000000d;
  box-shadow: 0px 114px 46px 0px #00000003;
  box-shadow: 0px 179px 50px 0px #00000000;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  background-color: #fff;
}

.dropdown_itemed {
  padding: 4px 6px;
  border: none;
  text-wrap: nowrap;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}

.dropdown_itemed:hover {
  background-color: #e6eefa;
  border-radius: 4px;
  border: none;
}
.hr_line {
  border-top: 1px solid #f8f9fa;
}
.no_hover:hover {
  background-color: transparent;
  cursor: default;
}
.border_top {
  border-top: 1px solid #ccc;
}

.border_bottom {
  border-bottom: 1px solid #ccc;
}
