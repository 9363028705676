.mainPage {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
}
.headerSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.headerSectionTextP {
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.costText {
  font-weight: 700;
  font-size: 21px;
  color: #212224;
}
.costTextp {
  font-weight: 400;
  font-size: 14px;
  color: #495057;
}
.inset{
  inset: 5px -162px auto auto !important;
}

/* ---- */
/* Modal Container */
.form_container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

/* Form Box */
.form_box {
  background: white;
  padding: 25px;
  width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Title */
.form_box h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Elements */
.form_group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: #797b80;
}
.labelMain {
  font-size: 13px;
  font-weight: 500;
  color: #212529;
}

.input,
select {
  width: 100%;
  padding: 8px;
  font-size: 1px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Filters & Tags */
.filter_row {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.add_btn {
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
}

.remove_btn {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
}

/* Buttons */
.button-group {
  display: flex;
  gap: 10px;
}

.delete_btn {
  background: red;
  color: white;
}

.cancel_btn {
  background: gray;
  color: white;
}

.create_btn {
  background: blue;
  color: white;
}
.discription {
  font-weight: 400;
  font-size: 12px;
  color: #797b80;
}
.Heading_Cross {
  font-size: 21px;
  font-weight: 700;
  color: #212224;
  display: flex;
  justify-content: space-between;
}
.deleted_button {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-D5, #d01a1a);
  background: var(--White, #fff);
  color: var(--Neutral-D4, #d01a1a);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}
.chartModalHeader{
  display: flex;
  gap: 10px;
  padding: 8px;
  justify-content: space-between;
}
.chartModalHeadertext{
  font-size: 18px;
  font-weight: 700;
  color: #212224;
}
.chartusertext{
  font-size:11px;
  font-weight:400;
  font-style:italic;
  color:#495057;
  display: flex;
  align-items: center;
}
.chartusertext2{
  font-size:11px;
  font-weight:400;
  color:#495057;
  display: flex;
  align-items: center;
}
.ChartSectionMain{
  display: flex;
  gap: 24px;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  padding: 20px;
  flex-direction: column;
  margin-top: 16px;
}
.chartStats{
display: flex;
gap: 44px;
}
.statsHeadings{
  font-size: 12px;
  font-weight: 500;
  color: #797B80;
  margin: 0px;
}
.chartStatsValue{
  font-size: 24px;
  font-weight: 700;
  color: #343A40;
}
.chartStatsValues{
  font-size: 13px;
  font-weight: 600;
  color: #495057;
}
.gap{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.chartspan{
  display: flex;
  align-items: center;
  gap: 4px;
}
.legends_Style ul {
  overflow-y: scroll;
  height: 46px;
  margin-top: 15px !important;
  scrollbar-width: none; /* For Firefox */
  padding-left: 20px !important;
}

.legends_Style ul::-webkit-scrollbar {
  display: none; /* For WebKit browsers */
}

.dropdown_icon {
  vertical-align: center;
  margin-right: 10px;
  width: 12px
}
.snap_table_data__Class {
  color: #212224;
  font-size: 13px;
  font-weight: 500;
  word-wrap: "break-word";
  background: none !important;
  height: 20px;
}