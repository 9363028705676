.dropdown_menu {
  left: 0 !important;
  right: 0 !important;
  border-radius: 4px;
  box-shadow: 0px 12px 27px 0px #0000001a;
  overflow-y: hidden;
  /* box-shadow: 
    0px 12px 27px 0px #0000001A,
    0px 49px 49px 0px #00000017,
    0px 111px 67px 0px #0000000D,
    0px 197px 79px 0px #00000003,
    0px 308px 86px 0px #00000000; */
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}

.filter_search_text_selected {
  color: red;
}

.dropdown_menu {
  max-height: 400px;
  border: 1px solid #FFFFFF;
}

.dropdown_menu .dropdown-item {
  background-color: red;
}

.dropdown_item {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.selected_item{
  background-color: #E6EEFA !important;
}

.radio {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.radio input {
  margin-right: 4px;
}

.radio label {
  width: 100%;
  font-size: 13px;
}

.loading_text {
  font-size: 13px;
}

.filter_search_field {
  position: relative;
  margin-bottom: 10px;
}

.filter_search_field input {
  height: 24px !important;
  padding-left: 20px;
  margin-bottom: 0px !important;
}

.filter_search_field::after {
  content: "";
  display: block;
  background-image: url("../../assets/img/search.svg");
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
}

.filter_search_text_selected {
  color: #000;
  /* display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px;
  min-width: 0; */
  text-wrap: nowrap;
    overflow-x: hidden;
    /* min-width: 55px; */
    text-overflow: ellipsis;
    width: 170px;
}

.filter_search_text_selected_text {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  /* max-width: 186px; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter_search_text_extras {
  flex-shrink: 0;
  background-color: #f0f6ff;
  padding: 0px 8px;
  border-radius: 4px;
  line-height: 20px;
}
.text_truncate {
  display: flow-root;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter_Dropdown:hover {
  background: #f0f6ff;
}
